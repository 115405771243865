import img from "@/assets/images/background/Website-Maintenance.jpg";
import BaseModal from "../BaseModal";

const MaintenanceModal = ({ showMaintanence }) => {
  return (
    <BaseModal>
      <div>
        <img
          src={img}
          className="mb-10 h-[220px] mx-auto rounded-md"
          alt="maintanence"
        />
        <span className="text-center flex justify-center text-title-md">
          {" "}
          Page under maintenance. We'll be back soon.
        </span>
      </div>
    </BaseModal>
  );
};

export default MaintenanceModal;
