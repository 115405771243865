import React, { useEffect, useContext } from "react";
import { useLandStore } from "@/store";
import { useLocation } from "react-router-dom";
import { useAllTilesData } from "@/hooks/useAllTilesData.js";
import useMapViewer from "../hooks/useMapViewer";
import { MapContext } from "../context";
import BuyLandSidebar from "../BuyLandSidebar";
import Button from "@/components/Common/Button";
import { Refresh } from "@/components/Icons";
import Loading from "../Loading";
import MaintenanceModal from '@/components/Common/MaintenanceModal'
const BuyLandMap = () => {
  const {
    viewerRef,
    handlerRef,
    selectedCells,
    setSelectedCells,
    clickCount,
    setClickCount,
    undoHandler,
    coordinates,
    setCoordinates,
    isPurchased,
    setIsPurchased,
    userOwnedTiles,
    setUserOwnedTiles,
    openTileModal,
    toggleModal,
    setSelectedCellsHistory,
  } = useContext(MapContext);

  const location = useLocation();
  const { userTiles, allTiles } = useLandStore();
  const { getAllTileData, isLoading } = useAllTilesData();

  useMapViewer({
    viewerRef,
    handlerRef,
    setSelectedCells,
    selectedCells,
    clickCount,
    setClickCount,
    coordinates,
    allTiles,
    userTiles,
    isPurchased,
    setIsPurchased,
    userOwnedTiles,
    setUserOwnedTiles,
    setCoordinates,
    isLoading,
    setSelectedCellsHistory,
  });

  useEffect(() => {
    if (coordinates) getAllTileData(coordinates);
  }, [location.search, coordinates]);

  return (
    <div id="cesiumContainer" className="cesium-container">
      <BuyLandSidebar />
      <MaintenanceModal showMaintanence />
      {isLoading && <Loading />}
      <Button
        model="tonal"
        size="md"
        onClick={undoHandler}
        className="!rounded-lg z-[1] absolute right-[116px] top-[8px] w-12 h-12 p-0"
      >
        <Refresh className="w-6 h-6" />
      </Button>

      <div className="absolute z-[1] bg-blackshade-900 shadow-frame rounded-[20px] p-4 bottom-12 desktop:bottom-8 right-4 left-4 desktop:left-auto desktop:right-8 grid grid-cols-12 desktop:w-[200px] flex-wrap justify-between gap-3">
        <div className="col-span-6 desktop:col-span-12 flex items-center justify-between">
          <span className="w-6 h-6 bg-orange-500" />
          <div className="text-label-lg">Sold Tiles</div>
        </div>
        <div className="col-span-6 desktop:col-span-12 flex items-center justify-between">
          <span className="w-6 h-6 bg-gold-500" />
          <div className="text-label-lg">Your Lands</div>
        </div>
        <div className="col-span-6 desktop:col-span-12 flex items-center justify-between">
          <span className="w-6 h-6 bg-gray-200" />
          <div className="text-label-lg">Your NFTS</div>
        </div>
        <div className="col-span-6 desktop:col-span-12 flex items-center justify-between">
          <span className="w-6 h-6 bg-green-500" />
          <div className="text-label-lg">Your Stakes</div>
        </div>
      </div>
    </div>
  );
};

export default BuyLandMap;
