import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { useAuth } from "@/context/authProvider.js";
import { api } from "@/utils/axiosInstance.js";
import { convertTilesToString } from "@/utils/landHelpers.js";
import ConnectWalletModal from "@/components/Common/ConnectWalletModal";
import BuyLandModal from "../BuyLandModal/index.jsx";
import Sidebar from "./components/Sidebar.jsx";
import { MapContext } from "../context.jsx";
import { useIsMobile } from "@/hooks/useIsMobile";
import BuyLandMobileNavbar from "../BuyLandMobileNavbar";
import { useToast } from "@/components/Common/Toast/utils";

const BuyLandSidebar = () => {
  const { tileNumber, selectedCells } = useContext(MapContext);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showBuyDetailModal, setShowBuyDetailModal] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isConnected, address } = useAccount();
  const { user } = useAuth();
  const isMobile = useIsMobile(1200);
  const { addToast } = useToast();

  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  const getLandData = async () => {
    setIsLoading(true);
    try {
      const res = await api.post("/get_purchase_price/", {
        tile_count: tileNumber,
        tile_data: convertTilesToString(selectedCells),
        user_address: address,
        token_type: "PME",
      });

      if (res.data.duplicated) {
         addToast("danger", "This land has already been bought.");
      } else if (res.data.reserved) {
         addToast("danger", "This land has already been reserved.");
      } else {
        setContractData(res.data.data);
        setShowBuyDetailModal(true);
      }
    } catch (err) {
      if (err.response.status === 429) {
        const unlockAt = err.response.data.unlock_at;
        const timeDifference = unlockAt - currentTime;
        if (timeDifference > 0) {
          const minutes = Math.floor((timeDifference % 3600) / 60);

          const minutesDisplay =
            minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""} ` : "";

           addToast("danger", `Try ${minutesDisplay} later`);
        } else {
          console.log("The unlock time has already passed.");
        }
      }
      console.log(err.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  const buyLandHandler = () => {
    // if (tileNumber < 10) {
    //    addToast("danger", "You must select at least 10 tiles.");
    // } else if (tileNumber > 100000) {
    //    addToast("danger", "You can select up to a maximum of 100,000 tiles.");
    // } else {
    //   if (user && isConnected) {
    //     const resetLockHandler = () => {
    //       api.post("/reset_lock/", { user_address: address });
    //     };
    //     // resetLockHandler()
    //     getLandData();
    //   } else {
    //     setShowConnectModal(true);
    //   }
    // }
  };

  return (
    <>
      <Sidebar isLoading={isLoading} buyLandHandler={buyLandHandler} />
      {isMobile ? (
        <BuyLandMobileNavbar
          isLoading={isLoading}
          buyLandHandler={buyLandHandler}
          profileBtnHandler={user && isConnected ? null : () => setShowConnectModal(true)}
        />
      ) : (
        ""
      )}

      {showConnectModal && (
        <ConnectWalletModal
          closeModalHandler={() => setShowConnectModal(false)}
        />
      )}
      {showBuyDetailModal && (
        <BuyLandModal
          closeModalHandler={() => setShowBuyDetailModal(false)}
          contractData={contractData}
        />
      )}
    </>
  );
};

export default BuyLandSidebar;
